/*
 * SPDX-License-Identifier: MIT
 *
 * Copyright 2021 Jeremy A Gray <gray@flyquackswim.com>.
 */

import React from 'react';

import './Body.css';

export const Body = () => {
  return (
    <div className="AppBody container-fluid m-0 p-0">
      <h2>
        Tasks
      </h2>
    </div>
  );
}

export default Body;
