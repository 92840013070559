/*
 * SPDX-License-Identifier: Proprietary
 *
 * Copyright 2021 Jeremy A Gray <gray@flyquackswim.com>.
 */

// React and friends.
import React from 'react';

// Bootstrap.
import 'bootstrap/dist/css/bootstrap.min.css';

// Components.
import Body from './Body.js';
import Footer from './Footer.js';
import Header from './Header.js';

// Hooks.
import useTitle from './hooks/useTitle';

// CSS.
import './App.css';

const App = (props) => {
  useTitle('Tysk:  Get Stuff Done');

  return (
    <React.Fragment>
      <Header />
      <Body />
      <Footer />
    </React.Fragment>
  );
}

export default App;
