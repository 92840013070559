/*
 * SPDX-License-Identifier: Proprietary
 *
 * Copyright 2021 Jeremy A Gray <gray@flyquackswim.com>.
 */

// React.
import {
  useEffect
} from 'react';

export const useTitle = (title = 'Your Title Here') => {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;

    return () => {
      document.title = previousTitle;
    };
  }, [title]);

  return title;
}

export default useTitle;
